<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadAvatarPopupPage() {
    import('#components/Avatar/AvatarPopupPage/AvatarPopupPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarPopupAdvancedPage() {
    import(
      '#components/Avatar/AvatarPopupAdvancedPage/AvatarPopupAdvancedPage.svelte'
    )
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarPopupAvatarsPage() {
    import(
      '#components/Avatar/AvatarPopupAvatarsPage/AvatarPopupAvatarsPage.svelte'
    )
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarPopupCurrencyPage() {
    import(
      '#components/Avatar/AvatarPopupCurrencyPage/AvatarPopupCurrencyPage.svelte'
    )
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarPopupLangPage() {
    import('#components/Avatar/AvatarPopupLangPage/AvatarPopupLangPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarPopupWalletPage() {
    import(
      '#components/Avatar/AvatarPopupWalletPage/AvatarPopupWalletPage.svelte'
    )
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadAvatarButton() {
    import('#components/Avatar/AvatarButton/AvatarButton.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'AvatarPopupPage') loadAvatarPopupPage();
  if (component.id == 'AvatarPopupAdvancedPage') loadAvatarPopupAdvancedPage();
  if (component.id == 'AvatarPopupAvatarsPage') loadAvatarPopupAvatarsPage();
  if (component.id == 'AvatarPopupCurrencyPage') loadAvatarPopupCurrencyPage();
  if (component.id == 'AvatarPopupLangPage') loadAvatarPopupLangPage();
  if (component.id == 'AvatarPopupWalletPage') loadAvatarPopupWalletPage();
  if (component.id == 'AvatarButton') loadAvatarButton();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
