import type { Application } from '#js/Application';
import Module from './Module';

export default class Network extends Module {
  public connected = false;

  public constructor(application: Application) {
    super(application);
  }
}
