/* eslint-disable @typescript-eslint/naming-convention */
import type { Writable } from 'svelte/store';
import type * as types from 'framework7/types';
import type { id } from '@kumaly/orm';

export type { id, ids } from '@kumaly/orm';

export type query = { [key: string]: string | number };

export enum ApplicationState {
  LOADING = 'loading',
  INIT = 'init',
  SETUP = 'setup',
  READY = 'ready',
}

export interface ComponentParams {
  page: Page;
}

export interface ComponentSkeleton {
  id: string;
  params: object;
}

export interface ModuleSkeleton {
  id: string;
  params: object;
}

export interface TabSkeleton {
  id: string;
  pullToRefresh?: boolean;
  active?: boolean;
  components: ComponentSkeleton[];
}

export interface PageSkeleton {
  path: string;
  pullToRefresh?: boolean;
  infiniteLoad?: boolean;
  components: ComponentSkeleton[];
}

export interface Page extends PageSkeleton {
  el: HTMLElement;
  transaction: any; // Sentry transaction
  props: Props;
  query: query;
  title: Writable<string>;
}

export interface Tab {
  id: string;
  icon: string;
  title: string;
  path: string;
  iosDynamicNavbar?: boolean;
  preload?: boolean;
}

export interface Props {
  adventureId?: id;
  articleId?: id;
  contractId?: id;
  experienceId?: id;
  giftId?: id;
  collectionId?: id;
  variationId?: id;
}

export interface Loader {
  page: Page;
  promise: Promise<void>;
  nonce: Writable<number>;
  lastNonce: number;
  loaded: boolean;
}

export interface Tip {
  index: number;
  text: string;
  buttons: types.Dialog.DialogButton[];
  delay: number;
}
