import IndexedDB from '@kumaly/db-indexeddb';
import { WalletsIndexedDB } from '@kumaly/orm-wallets-indexeddb';
import type {
  GetAvatarCoreAMRK,
  GetWalletCoreWMRK,
  LoadAvatarFromDbAMRK,
  LoadWalletFromDbWMRK,
  WalletCoreBase,
} from '@kumaly/kernel-types';
import type { WalletDatas } from '@kumaly/orm-wallets';
import FormData from 'form-data';
import type { id } from '#js/Application';
import Module from './Module';

export default class Wallet extends Module {
  public getWallet(): WalletCoreBase {
    const result: GetWalletCoreWMRK =
      this.k.cores.wallets.getWalletCore('wallet');
    return result.datas;
  }

  public async load(): Promise<boolean> {
    this.k.walletsDb = new WalletsIndexedDB(new IndexedDB());

    await this.k.loadAvatarsManager();
    await this.k.loadPursesManager();
    await this.k.loadWalletsManager();

    const result1: LoadWalletFromDbWMRK =
      await this.k.cores.wallets!.loadWalletFromDb('wallet');
    if (!result1.success || !result1.datas) return false;

    await this.k.loadWallet();
    return true;
  }

  public async createWallet(): Promise<void> {
    await this.k.loadEthers();
    await this.k.cores.wallets!.initWallet();
    await this.k.loadWallet();
  }

  public async claimAvatar(
    avatarId: id,
    secret: string,
    referral: id
  ): Promise<boolean> {
    // Load avatar from gateway
    await this.application.d.loadAvatars();
    const avatar = await this.application.d.avatars!.getOne(avatarId);
    console.log(avatar);
    if (!avatar) return false;

    await this.k.loadAvatarsModule();

    // If avatar in local wallet and same addresses, return true
    if (this.k.wallet.wallet.avatarsIds.indexOf(avatarId) !== -1) {
      const result1: LoadAvatarFromDbAMRK =
        await this.k.cores.avatars.loadAvatarFromDb(this.k.wallet.id, avatarId);
      const core = result1.datas;
      console.log(`${core.avatar.addressId} vs ${avatar.addressId}`);
      if (core.addressId === avatar.addressId) return true;
    }

    // If avatar dont have challenge, return false
    if (!avatar.challenge || !avatar.challenge.length) return false;

    // If secret is invalid for challenge, return false
    if (avatar.challenge !== secret) return false;

    // Add avatar to local wallet
    const index = this.k.wallet.avatarsIds.length;
    const result = await this.k.wallet.avatars!.getSignerAtIndex(index);
    const addressId = result.datas.address;
    await this.k.wallet.avatars.createAvatarInDb({
      id: avatarId,
      nonce: 0,
      addressId,
    });

    // Sign claim request with local avatar and send it
    const result1: LoadAvatarFromDbAMRK =
      await this.k.cores.avatars.loadAvatarFromDb(this.k.wallet.id, avatarId);
    const core = result1.datas;

    const result2 = await this.k.modules.avatars.claim(
      core,
      avatarId,
      core.avatar.addressId,
      secret,
      referral,
      new FormData()
    );
    console.log(result2);

    if (!result2.success) {
      // TODO remove avatar from local wallet
      return false;
    }

    await this.application.wallet.setAvatarId(avatarId);
    return true;
  }

  public async restore(wallet: WalletDatas): Promise<void> {
    await this.k.cores.wallets!.restoreWallet(wallet);
    this.a.reload(1000);
  }

  public async setAvatarId(
    avatarId: id,
    reload: boolean = false
  ): Promise<void> {
    this.k.wallet.wallet.avatarId = avatarId;
    await this.k.wallet.saveWallet();

    if (reload) this.a.reload(1000);
  }
}
