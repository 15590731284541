<script lang="ts">
  import { A, type Page } from '#js/Application';
  import Component from './NavBar/Component.svelte';
  import AreaComponent from './Area/Component.svelte';
  import AvatarComponent from './Avatar/Component.svelte';
  import HowtoComponent from './Howto/Component.svelte';
  import NavBarComponent from './NavBar/Component.svelte';
  import SidebarComponent from './Sidebar/Component.svelte';
  import TabComponent from './Tab/Component.svelte';
  import TransactionComponent from './Transaction/Component.svelte';
  import CustomComponents from '#app/components/Components.svelte';

  export let page: Page;
  export let components;
</script>

{#each components as component}
  {@const params = A.cloneObjectSurcharge(component.params, { page })}

  <Component {params} {component} />
  <AreaComponent {params} {component} />
  <AvatarComponent {params} {component} />
  <HowtoComponent {params} {component} />
  <NavBarComponent {params} {component} />
  <SidebarComponent {params} {component} />
  <TabComponent {params} {component} />
  <TransactionComponent {params} {component} />
{/each}
<CustomComponents {page} {components} />
