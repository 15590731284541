import type { Viewer } from '@kumaly/orm';
import type { Page, Props, Loader, PageSkeleton } from '#js/Interfaces';
import Module from './Module';

export default class Pages extends Module {
  public refreshing: { [key: string]: boolean } = {};

  public loadingMore: { [key: string]: boolean } = {};

  public loadPageByPath(
    path: string,
    props: Props,
    query: { [key: string]: any }
  ): Page {
    const skeletonPage: PageSkeleton = this.a.skeleton.getPageByPath(path);
    if (!skeletonPage) return null;

    // Dont modify base skeleton
    const page = this.a.cloneObject(skeletonPage) as Page;
    page.props = props;
    page.query = query;
    page.title = this.a.initStringNonce();
    return page;
  }

  public async loadPageComplete(page: Page): Promise<void> {
    await this.a.tracking.trackPageView(page.path);
  }

  public initPageLoader(page: Page): Loader {
    return {
      page,
      promise: null,
      nonce: this.a.initNumberNonce(),
      lastNonce: 0,
      loaded: false,
    };
  }

  public refreshPage(page: Page, done: Function): void {
    this.e.pageRefresh.set('');
    this.e.pageRefresh.set(page.path);
    this.refreshPageCheckLoop(done);
  }

  public refreshPageCheckLoop(done: Function) {
    setTimeout(() => {
      if (Object.keys(this.refreshing).length === 0) {
        done();
        return;
      }

      this.refreshPageCheckLoop(done);
    }, 100);
  }

  public async refreshPageViewer(
    loader: Loader,
    triggerPage: string,
    viewer: Viewer,
    prepare: Function
  ): Promise<void> {
    if (!loader.loaded || triggerPage !== loader.page.path) return;

    this.refreshing[loader.page.path] = true;
    // TODO
    // eslint-disable-next-line no-param-reassign
    viewer.dto.page = 1;
    await viewer.load();
    await prepare();
    delete this.refreshing[loader.page.path];

    // eslint-disable-next-line no-param-reassign
    loader.lastNonce += 1;
    loader.nonce.update(() => loader.lastNonce);
  }

  /*

    LOAD MORE CONTENT

    1. page.svelte trigger loadMorePage onInfinite
    2. if there is not already a loadMore running for that page trigger event with page path
    3. page component catch the event and await for loadMorePageViewer giving the viewer and a prepare method
    4. loadMorePageViewer has to check the triggerPage because all pages receive all events and we factorise that here
    5. The viewer is updated with datas for next page only if no loadMore for that page has trigger in between
       This is important b/c if user scroll very fast, multiple events are going to trigger and some resources
       are not loaded properly
    6. If the viewer has reach the last page, then nothing is done
    7. The loadMorePageIntoViewer lock loadMore for that page and fetch datas
       It also call the event pageLoadMoreStarted for that page to display the loading image
    8. Once viewer has finish to load the prepare method is called back to load additional content like resources
    9. Then the lock for loadMore for that page is removed
    10. loadMorePageLoop will see that the lock has been removed on it's next check and trigger done
    11. done() is pageLoadMoreDone() in page.svelte that will remove the loading image

  */

  public loadMorePage(page: Page, done: Function): void {
    if (!this.loadMorePageCheck(page)) return;

    this.e.pageLoadMore.set('');
    this.e.pageLoadMore.set(page.path);
    this.loadMorePageLoop(page, done);
  }

  public loadMorePageLoop(page: Page, done: Function): void {
    setTimeout(() => {
      if (this.loadMorePageCheck(page)) {
        done();
        return;
      }

      this.loadMorePageLoop(page, done);
    }, 100);
  }

  public loadMorePageCheck(page: Page): boolean {
    return !Object.prototype.hasOwnProperty.call(this.loadMorePage, page.path);
  }

  public async loadMorePageViewer(
    loader: Loader,
    triggerPage: string,
    viewer: Viewer,
    prepare: Function
  ): Promise<void> {
    if (!loader.loaded || triggerPage !== loader.page.path) return;

    if (await this.loadMorePageIntoViewer(loader.page, viewer)) {
      await prepare();
      delete this.loadingMore[loader.page.path];
    }
  }

  public async loadMorePageIntoViewer(
    page: Page,
    viewer: Viewer
  ): Promise<boolean> {
    if (!this.loadMorePageCheck(page)) return false;
    if (viewer.dto.page === viewer.pages) return false;

    this.loadingMore[page.path] = true;
    viewer.setNextPage();

    // Show infinitePreloader
    this.e.pageLoadMoreStarted.set('');
    this.e.pageLoadMoreStarted.set(page.path);

    await viewer.loadMore();
    return true;
  }

  public checkPageHasPullToRefresh(page: Page): boolean {
    return this.a.skeleton.checkPageHasPullToRefresh(page);
  }

  public checkPageHasInfiniteLoad(page: Page): boolean {
    return this.a.skeleton.checkPageHasInfiniteLoad(page);
  }

  public checkPageHasSubNavBarSegmented(page: Page): boolean {
    return this.a.skeleton.checkPageHasSubNavBarSegmented(page);
  }

  public getPageSubNavBar(page: Page): any {
    return this.a.skeleton.getPageSubNavBar(page);
  }
}
