<script lang="ts">
  // @ts-ignore
  import { getDevice } from 'framework7/lite';
  import { onMount } from 'svelte';
  import { f7, f7ready, App } from 'framework7-svelte';
  import { A } from '#js/Application';
  import routes from '#js/routes';
  import Setup from '#app/components/Setup/Setup/Setup.svelte';
  import Skeleton from './Skeleton.svelte';

  let state: string;
  A.state.subscribe((value) => {
    state = value;
  });

  let dark: boolean;
  A.e.darkMode.subscribe((value) => {
    dark = value;
  });
  // dark = true;

  const device = getDevice();
  let f7params = {
    name: 'APP',
    theme: 'ios',
    id: 'io.framework7.myapp',
    routes: routes,
    input: {
      scrollIntoViewOnFocus: device.capacitor,
      scrollIntoViewCentered: device.capacitor,
    },
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    lazy: {
      observer: false,
      threshold: 250,
    },
  };

  onMount(() => {
    f7ready(() => {
      // Call F7 APIs here
    });
  });
</script>

<App {...f7params} {dark} class={dark ? 'dark' : 'light'}>
  {#if state == 'ready'}
    <Skeleton />
  {/if}

  {#if state == 'setup'}
    <Setup params={{ page: null }} />
  {/if}

  <!-- STATE : {state} -->
</App>
