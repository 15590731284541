/*
import Framework7, {
  request,
  utils,
  getDevice,
  createStore,
} from 'framework7/lite';
*/
import Framework7 from 'framework7/lite';

// @ import Accordion from 'framework7/components/accordion';
// import Actions from 'framework7/components/actions';
import Appbar from 'framework7/components/appbar';
// import Autocomplete from 'framework7/components/autocomplete';
// import Calendar from 'framework7/components/calendar';
// @ import Card from 'framework7/components/card';
// @ import Checkbox from 'framework7/components/checkbox';
// import Chip from 'framework7/components/chip';
// import ColorPicker from 'framework7/components/color-picker';
// import ContactsList from 'framework7/components/contacts-list';
// import DataTable from 'framework7/components/data-table';
// import Dialog from 'framework7/components/dialog';
// import Elevation from 'framework7/components/elevation';
// import Fab from 'framework7/components/fab';
// import Form from 'framework7/components/form';
// import Grid from 'framework7/components/grid';
import InfiniteScroll from 'framework7/components/infinite-scroll';
// import Input from 'framework7/components/input';
import Lazy from 'framework7/components/lazy';
// import ListIndex from 'framework7/components/list-index';
// import LoginScreen from 'framework7/components/login-screen';
// import Menu from 'framework7/components/menu';
// import Messagebar from 'framework7/components/messagebar';
// import Messages from 'framework7/components/messages';
// import Notification from 'framework7/components/notification';
import Panel from 'framework7/components/panel';
// import PhotoBrowser from 'framework7/components/photo-browser';
// import Picker from 'framework7/components/picker';
// import Popover from 'framework7/components/popover';
// @ import Popup from 'framework7/components/popup';
import Preloader from 'framework7/components/preloader';
// import Progressbar from 'framework7/components/progressbar';
import PullToRefresh from 'framework7/components/pull-to-refresh';
// @ import Radio from 'framework7/components/radio';
// @ import Range from 'framework7/components/range';
// import Searchbar from 'framework7/components/searchbar';
// import Sheet from 'framework7/components/sheet';
// import Skeleton from 'framework7/components/skeleton';
// import SmartSelect from 'framework7/components/smart-select';
// import Sortable from 'framework7/components/sortable';
// import Stepper from 'framework7/components/stepper';
// import Swipeout from 'framework7/components/swipeout';
// import Swiper from 'framework7/components/swiper';
import Tabs from 'framework7/components/tabs';
// import TextEditor from 'framework7/components/text-editor';
// import Timeline from 'framework7/components/timeline';
// import Toast from 'framework7/components/toast';
// import Toggle from 'framework7/components/toggle';
// import Tooltip from 'framework7/components/tooltip';
// import Treeview from 'framework7/components/treeview';
// import Typography from 'framework7/components/typography';
// import VirtualList from 'framework7/components/virtual-list';

Framework7.use([
  // Accordion,
  // Actions,
  Appbar,
  // Autocomplete,
  // Calendar,
  // Card,
  // Checkbox,
  // Chip,
  // ColorPicker,
  // ContactsList,
  // DataTable,
  // Dialog,
  // Elevation,
  // Fab,
  // Form,
  // Grid,
  InfiniteScroll,
  // Input,
  Lazy,
  // ListIndex,
  // LoginScreen,
  // Menu,
  // Messagebar,
  // Messages,
  // Notification,
  Panel,
  // PhotoBrowser,
  // Picker,
  // Popover,
  // Popup,
  Preloader,
  // Progressbar,
  PullToRefresh,
  // Radio,
  // Range,
  // Searchbar,
  // Sheet,
  // Skeleton,
  // SmartSelect,
  // Sortable,
  // Stepper,
  // Swipeout,
  // Swiper,
  Tabs,
  // TextEditor,
  // Timeline,
  // Toast,
  // Toggle,
  // Tooltip,
  // Treeview,
  // Typography,
  // VirtualList,
]);

export default Framework7;
// export { request, utils, getDevice, createStore };
