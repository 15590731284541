import { uncompactContractId, type Contract } from '@kumaly/orm-contracts';
import {
  convertRarity,
  convertStoresToVariations,
  getAddressQuantities,
  getAddressQuantitiesMulti,
  getAddressVariationsOwned,
  getAddressVariationsSelling,
  getEmptyStoreUnpacked,
  getLootboxVariationsCount,
  getVariationCirculating,
  getVariationSelling,
  getVariationsSelling,
  type SftContractRecipe,
  type SftContractRecipePut,
} from '@kumaly/sft';
import type {
  ClaimLootboxSftDto,
  ClaimLootboxSftRK,
  CraftLootboxSftDto,
  CraftLootboxSftRK,
  OpenLootboxSftDto,
  OpenLootboxSftRK,
} from '@kumaly/kernel-types';
import type { Variations } from '@kumaly/orm-variations';
import type { id, ids } from '#js/Application';
import Module from './Module';

export default class SFT extends Module {
  public async getContract(contractId: id): Promise<Contract> {
    await this.a.d.loadContracts();
    return this.a.d.contracts.getOne(contractId);
  }

  public async getPot(contractId: id): Promise<string> {
    const contract = await this.getContract(contractId);
    return this.a.k.ethers.utils
      .formatEther(contract.storage.balance)
      .substring(0, 7);
  }

  public async getLootboxesCount(contractId: id): Promise<number> {
    const contract = await this.getContract(contractId);
    return getAddressQuantities(
      this.a.k.ethers.BigNumber,
      contract.storage,
      this.a.k.purse.addressId,
      0
    )[72];
  }

  public async getLootboxPrice(contractId: id): Promise<string> {
    const contract = await this.getContract(contractId);
    return this.a.k.ethers.utils.formatEther(contract.state.lootbox!.price);
  }

  public async getVariationsTotal(contractId: id): Promise<number> {
    const contract = await this.getContract(contractId);
    return getLootboxVariationsCount(contract.state);
  }

  public async getVariationsOwned(contractId: id): Promise<number> {
    const contract = await this.getContract(contractId);
    const variations = getAddressVariationsOwned(
      this.a.k.ethers.BigNumber,
      contract.storage,
      this.a.k.purse.addressId,
      0
    );
    return variations;
  }

  public async getVariationsCount(contractId: id): Promise<number[]> {
    const contract = await this.getContract(contractId);
    if (!this.a.k.purse) return getEmptyStoreUnpacked().quantities;

    return getAddressQuantities(
      this.a.k.ethers.BigNumber,
      contract.storage,
      this.a.k.purse.addressId,
      0
    );
  }

  // TODO indexes should be deduced from state datas (max inventory index)
  public async getVariationsCountMulti(contractId: id): Promise<number[]> {
    const contract = await this.getContract(contractId);
    if (!this.a.k.purse) return getEmptyStoreUnpacked().quantities;

    return getAddressQuantitiesMulti(
      this.a.k.ethers.BigNumber,
      contract.storage,
      this.a.k.purse.addressId,
      5 // TODO
    );
  }

  public async getVariationCount(
    contractId: id,
    variation: number
  ): Promise<number> {
    return (await this.getVariationsCount(contractId))[variation];
  }

  public async getVariationsSelling(contractId: id): Promise<number[]> {
    const contract = await this.getContract(contractId);
    if (!this.a.k.purse) return getEmptyStoreUnpacked().quantities;

    return getAddressVariationsSelling(
      contract.state,
      this.a.k.purse.addressId
    );
  }

  public async getVariationsMarket(contractId: id): Promise<number[]> {
    const contract = await this.getContract(contractId);
    if (!this.a.k.purse) return getEmptyStoreUnpacked().quantities;

    return getVariationsSelling(contract.state);
  }

  public async getVariationSelling(
    contractId: id,
    variation: number
  ): Promise<number> {
    const contract = await this.getContract(contractId);
    return getVariationSelling(contract.state, variation);
  }

  public async getVariationCirculating(
    contractId: id,
    variation: number
  ): Promise<number> {
    const contract = await this.getContract(contractId);
    return getVariationCirculating(
      this.a.k.ethers.BigNumber,
      contract.storage,
      variation
    );
  }

  public async openLootbox(
    contractId: id,
    quantityBuy: number,
    quantityOpen: number
  ): Promise<void> {
    await this.a.k.loadSftContract();
    const contract = await this.getContract(contractId);

    const dto: OpenLootboxSftDto = {
      purse: this.a.k.purse,
      contract,
      quantityBuy,
      quantityOpen,
    };

    const result: OpenLootboxSftRK =
      await this.a.k.contracts.sft.call.openLootbox(dto);
    await this.playResultInUnity(contract, result);
  }

  public async craftLootbox(contractId: id, rarity: number, quantity: number) {
    await this.a.k.loadSftContract();
    const contract = await this.getContract(contractId);

    const dto: CraftLootboxSftDto = {
      purse: this.a.k.purse,
      contract,
      rarity: convertRarity(rarity),
      quantity,
    };

    const result: CraftLootboxSftRK =
      await this.a.k.contracts.sft.call.craftLootbox(dto);
    await this.playResultInUnity(contract, result);
  }

  public async claimLootbox(contractId: id): Promise<void> {
    await this.a.k.loadSftContract();
    const contract = await this.getContract(contractId);

    const dto: ClaimLootboxSftDto = {
      purse: this.a.k.purse,
      contract,
      minBalance: contract.storage.balance,
    };

    const result: ClaimLootboxSftRK =
      await this.a.k.contracts.sft.call.claimLootbox(dto);
    await this.playResultInUnity(contract, result);
  }

  private async playResultInUnity(
    contract: Contract,
    result: OpenLootboxSftRK | CraftLootboxSftRK
  ) {
    if (!result.success) {
      console.log(result);
      return;
    }

    console.log(result);

    // const infos = uncompactContractId(contract.id);
    // await this.a.prepareUnity(infos.collectionId, result.datas, contract);
    this.a.openPopup('unity');
  }

  // eslint-disable-next-line class-methods-use-this
  public checkCanClaimRecipe(
    variationsCount: number[],
    variationsIdsBurn: string[],
    variations: Variations
  ): boolean {
    let check: boolean = true;

    for (let i = 0; i < variationsIdsBurn.length; i += 1) {
      const variation = variations.find(variationsIdsBurn[i]);
      if (!variation || variationsCount[variation.localPosition] === 0)
        check = false;
    }

    return check;
  }

  // eslint-disable-next-line class-methods-use-this
  public getVariationsIdsFromPut(put: SftContractRecipePut): ids {
    const variationsIds: ids = [];
    const infos = uncompactContractId(put.contractId);
    const result = convertStoresToVariations(put.stores);
    for (let i = 0; i < result.variations.length; i += 1)
      if (result.quantities[i] > 0)
        variationsIds.push(`${infos.collectionId}_${result.variations[i]}`);
    return variationsIds;
  }

  public async getRecipe(
    contractId: id,
    recipeId: id
  ): Promise<SftContractRecipe | null> {
    await this.a.d.loadContracts();
    const contract = await this.a.d.contracts.getOne(contractId);
    if (!contract) {
      console.log('Cannot load contract');
      return null;
    }

    for (let i = 0; i < contract.state.crafting.recipes.length; i += 1) {
      const contractRecipe = contract.state.crafting.recipes[i];
      const contractRecipeId = `${contract.id}_${contractRecipe.id}`;
      if (contractRecipeId === recipeId) return contractRecipe;
    }

    return null;
  }
}
