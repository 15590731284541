import { A } from '#js/Application';

import Page from '#pages/page.svelte';
import NotFoundPage from '#pages/404.svelte';

const routes = [];

for (let i = 0; i < A.skeleton.pages.length; i += 1)
  routes.push({
    path: A.skeleton.pages[i].path,
    component: Page,
  });

routes.push({ path: '(.*)', component: NotFoundPage });

export default routes;
