/* eslint-disable import/no-named-default */
import { default as common } from '#config/icons.json';
import { default as app } from '#app/config/icons.json';

export class Icons {
  public icons: { [key: string]: string } = {};

  public constructor() {
    Object.keys(common).forEach((key: string) => {
      this.icons[key] = common[key];
    });

    Object.keys(app).forEach((key: string) => {
      this.icons[key] = app[key];
    });
  }

  public get(id: string, fill = false): string {
    return `${this.icons[id]}${fill ? '_fill' : ''}`;
  }
}

export const icons = new Icons();
