import {
  FrontEventType,
  type FrontEventPageView,
} from '@kumaly/orm-frontevents';
import FormData from 'form-data';
import Module from './Module';

export default class Tracking extends Module {
  public async trackPageView(path: string) {
    await this.application.k.loadFrontEventsModule();

    // this.application.km.frontEvents!.mint(
    //   {
    //     id: 'test',
    //     encoding: 0,
    //     nonce: 0,
    //     applicationId: this.application.id,
    //     type: FrontEventType.PAGE_VIEW,
    //     ip: '',
    //     properties: {
    //       fingerprint: this.application.client.getFingerprint(),
    //       path,
    //     } as FrontEventPageView,
    //   },
    //   new FormData()
    // );
  }

  // TODO
  public trackPopupFilter() {}
}
