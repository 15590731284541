import { writable } from 'svelte/store';

// TODO Should it be on Application instead ?
export default class Events {
  public clientWidth = writable(0);

  public darkMode = writable(false);

  public purse = writable(false);

  public auth = writable(false);

  public online = writable(false);

  public pageRefresh = writable('');

  public pageLoadMore = writable('');

  public pageLoadMoreStarted = writable('');

  public storeRefresh = writable(0);

  public profileRefresh = writable(0);

  public actionDatasRefresh = writable(0);

  public notifications = writable(0);

  public langId = writable('');

  public currencyId = writable('');

  public adventureGiftPopupAdventureId = writable('');

  public sftPopupVariationId = writable('');

  public lootboxPopupCollectionId = writable('');

  public shopPopupCollectionId = writable('');

  public unityPopupStore = writable('');

  public unityPopupCollectionId = writable('');

  public unityPopupTransactionHash = writable('');

  public lastNotificationId = writable('');

  public howtoPopupArticleId = writable('');

  public transactionPopupPayload = writable({});

  public setupPayload = writable({});
}
