<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadSidebarTabs() {
    import('#components/Sidebar/SidebarTabs/SidebarTabs.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'SidebarTabs') loadSidebarTabs();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
