<script lang="ts">
  import { A, onMount } from '#js/Application';
  import type { SetupOnboardingParams } from './Interfaces';
  import Button from '#components/Button/Button.svelte';
  import { f7, Swiper, SwiperSlide } from 'framework7-svelte';

  export let params: SetupOnboardingParams;

  let show: boolean = false;

  onMount(async () => {
    await A.framework7.loadSwiperModule();

    // @ts-ignore
    f7.swiper.destroy('.swiper');
    f7.swiper.create('.swiper', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
      },
      speed: 400,
    });
  });

  async function start() {
    show = true;
    // await A.setup.start();
  }
</script>

<div class="h-full text-black">
  <Swiper pagination class="h-full">
    <SwiperSlide>
      <div class="h-[50%] bg-gray-300 mb-10" />
      <div class="text-center text-3xl px-2">
        Follow Experiences Luxe and win luxurious gifts

        <div class="mt-12 text-xs text-gray-300">
          version {A.version}
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="h-[50%] bg-gray-400 mb-10" />
      <div class="text-center text-3xl px-2">
        Collect all the memories from one particular adventure to win the
        associated gift
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="h-[50%] bg-gray-500 mb-10" />
      <div class="text-center text-3xl px-2">
        Luxurious gifts from $30 to $3000
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="h-[50%] bg-gray-600 mb-10" />
      <div class="text-center text-3xl px-2">
        More than 300 memories to collect from delicious moments all over the
        world!
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="h-[50%] bg-gray-700 mb-10" />
      <div class="text-center text-3xl px-2">
        Follow Experiences Luxe on Instagram, every like and comment give you a
        chance to collect memories
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div class="h-[50%] bg-gray-800 mb-10" />
      {#if !show}
        <div class="text-center text-3xl px-2">
          Install the app to receive your 3 welcome memories
          <div class="mx-[20%] my-6">
            <Button
              params={{
                page: params.page,
                text: 'INSTALL APP',
                onClick: start,
              }}
            />
          </div>
        </div>
      {:else}
        <div class="text-center text-xl px-2">
          <div class="my-6 bg-secondary p-4">
            <slot name="howto">HOWTO</slot>
          </div>
        </div>
      {/if}
    </SwiperSlide>
  </Swiper>
</div>

<style>
  :global(.swiper-slide) {
    background: #fff;
    text-align: center;
    font-size: 18px;
    line-height: 200px;
  }
</style>
