<script lang="ts">
  import { A } from '#js/Application';
  import { Panel, Views, View, Tab, Toolbar, f7 } from 'framework7-svelte';
  import Link from '#components/Link/Link/Link.svelte';
  import HowtoPopup from './Howto/HowtoPopup/HowtoPopup.svelte';
  import TransactionPopup from './Transaction/TransactionPopup/TransactionPopup.svelte';

  let tabs = {};
  for (let i = 0; i < A.skeleton.tabs.length; i++)
    tabs[A.skeleton.tabs[i].id] =
      A.skeleton.tabs[i].id == 'home' || A.skeleton.tabs[i].preload
        ? true
        : false;

  // TODO externalize
  function activateTab(tabId: string) {
    if (f7.views[tabId]) {
      let last = f7.views[tabId].history[f7.views[tabId].history.length - 1];

      // First trigger is done by page.svelte:pageAfterInTrigger
      if (last == '/notifications') {
        console.log('/notifications');
        // A.k.setUserNotificationsTimestamp();
      }
    }

    tabs[tabId] = true;
    tabs = tabs;
  }
</script>

<Panel left cover visibleBreakpoint={1024}>
  <View url="/sidebar" />
</Panel>

<Views tabs class="safe-areas">
  <Toolbar id="toolbar" tabbar labels bottom class="lg:hidden">
    {#each A.skeleton.tabs as tab}
      {#if tab.id === 'notifications'}
        <Link
          params={{
            type: 'tab',
            icon: tab.icon,
            title: tab.title,
            tab: tab.id,
            badge: A.e.notifications,
          }}
        />
      {:else}
        <Link
          params={{
            type: 'tab',
            icon: tab.icon,
            title: tab.title,
            tab: tab.id,
          }}
        />
      {/if}
    {/each}
  </Toolbar>

  {#each A.skeleton.tabs as tab}
    <Tab
      id="tab-{tab.id}"
      style="height:100%"
      tabActive={tab.id == 'home'}
      onTabShow={() => activateTab(tab.id)}
    >
      {#if tabs[tab.id]}
        <View
          id="view-{tab.id}"
          name={tab.id}
          main={tab.id == 'home'}
          url={tab.path}
          iosDynamicNavbar={tab.iosDynamicNavbar === undefined ||
            tab.iosDynamicNavbar === true}
        />
      {/if}
    </Tab>
  {/each}

  <TransactionPopup params={{}} />
  <HowtoPopup params={{}} />
</Views>
