<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadMemoriesPage() {
    import('#app/components/Memory/MemoriesPage/MemoriesPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadMemoryPopupPage() {
    import('#app/components/Memory/MemoryPopupPage/MemoryPopupPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'MemoriesPage') loadMemoriesPage();
  if (component.id == 'MemoryPopupPage') loadMemoryPopupPage();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
