<script lang="ts">
  import { A } from '#js/Application';
  import type { SetupIosParams } from './Interfaces';
  import { SetupPayloadMode } from '#js/modules/Setup';
  import SetupOnboarding from '../SetupOnboarding/SetupOnboarding.svelte';

  export let params: SetupIosParams;
</script>

{#if params.payload.mode === SetupPayloadMode.MOBILE_IOS_INSTAGRAM}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">INSTAGRAM IOS</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div>
        To participate and win awesome gifts, you need to install EL app.
      </div>
      <div class="mt-4">Click the 3 dots, then Open in system browser.</div>
    </div>
  </div>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_IOS_SAFARI}
  <!-- <div class="bg-gray-200 m-0 p-4 text-center">IOS SAFARI</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div class="mt-4">
        First, click on the button X located on the bottom of your screen.
      </div>
      <div class="mt-4">
        Then choose the option <strong>Add to Home Screen</strong>
      </div>
      <div class="mt-4">
        Once the installation has completed, open the app like any other app on
        your phone.
      </div>
      <div class="mt-4">Fingerprint: {A.client.getFingerprint()}</div>
    </div> -->
  <SetupOnboarding {params}>
    <div slot="howto">
      <div class="text-xl">iOS HOWTO</div>
      <div class="mt-4">
        First, click on the button X located on the bottom of your screen.
      </div>
      <div class="mt-4">
        Then choose the option <strong>Add to Home Screen</strong>
      </div>
      <div class="mt-4">
        Once the installation has completed, open the app like any other app on
        your phone.
      </div>
    </div>
  </SetupOnboarding>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_IOS_BROWSER}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">IOS NOT SAFARI</div>
    <div class="bg-gray-200 mt-4 p-4">
      <!-- <div>
        To participate and win awesome gifts, you need to install EL app.
      </div>
      <div class="mt-4">
        First, open that link in the Safari browser (click to copy):
      </div>
      <div class="mt-4 font-bold text-center">
        https://www.experiencesluxe.com/app
      </div> -->
      <div class="mt-4">You cannot use {A.client.client.getBrowser()}</div>
    </div>
  </div>
{/if}

{#if params.payload.mode === SetupPayloadMode.MOBILE_IOS_PWA}
  <div class="h-full w-full bg-gray-400 p-4 text-black">
    <div class="bg-gray-200 m-0 p-4 text-center">IOS PWA</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div>Loading...</div>
    </div>
  </div>
{/if}
