<script lang="ts">
  // import { Page, Navbar, Block } from 'framework7-svelte';
  import { Page } from 'framework7-svelte';

  function pageAfterInTrigger() {
    console.log(`PAGE : 404`);
  }
</script>

<Page on:pageAfterIn={pageAfterInTrigger}>
  <!--
  <Navbar title="Not found" backLink="Back" />
  <Block strong>
    <p>Sorry</p>
    <p>Requested content not found.</p>
  </Block>
  -->
</Page>
