<script lang="ts">
  import type { NavBarParams } from './Interfaces';
  import { A } from '#js/Application';
  import { Navbar, NavTitle, NavLeft, NavRight, f7 } from 'framework7-svelte';
  import Components from '#components/Components.svelte';
  import Link from '#components/Link/Link/Link.svelte';
  import SubNavBarSegmented from '../SubNavBarSegmented/SubNavBarSegmented.svelte';

  export let params: NavBarParams;
  let title: string = params.title;

  params.page.title.subscribe((value: string) => {
    if (!value.length) return;

    title = value;
  });
</script>

<Navbar>
  {#if params.back}
    <NavLeft backLink={A.t(params.back)} />
  {/if}

  <NavTitle>
    {#if title}
      {A.t(title)}
    {/if}
  </NavTitle>

  {#if params.right}
    <NavRight>
      {#if params.right.hasOwnProperty('popover')}
        <Link
          params={{
            page: params.page,
            type: 'action',
            icon: 'actions',
            iconSize: 30,
            popover: params.right.popover,
          }}
        />
      {/if}
      {#if params.right.hasOwnProperty('popup')}
        <Link
          params={{
            page: params.page,
            type: 'action',
            icon: 'actions',
            iconSize: 30,
            popup: params.right.popup,
          }}
        />
      {/if}
      {#if params.right.hasOwnProperty('component')}
        <Components page={params.page} components={[params.right.component]} />
      {/if}
    </NavRight>
  {/if}

  {#if A.pages.checkPageHasSubNavBarSegmented(params.page)}
    <SubNavBarSegmented params={A.pages.getPageSubNavBar(params.page).params} />
  {/if}
</Navbar>
