<script lang="ts">
  import { A } from '#js/Application';
  import type { SetupDesktopParams } from './Interfaces';
  import { SetupPayloadMode } from '#js/modules/Setup';

  export let params: SetupDesktopParams;
</script>

<div class="h-full w-full bg-gray-400 p-4 text-black">
  {#if params.payload.mode === SetupPayloadMode.DESKTOP}
    <div class="bg-gray-200 m-0 p-4 text-center">DESKTOP</div>
    <div class="bg-gray-200 mt-4 p-4">
      <div class="">Fingerprint: {A.client.getFingerprint()}</div>
      <div class="mt-4">Browser: {A.client.client.getBrowser()}</div>
    </div>
  {/if}
</div>
