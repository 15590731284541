import type { Application } from '#js/Application';
import Module from './Module';

export enum SetupPayloadMode {
  MOBILE_ANDROID_INSTAGRAM = 'mobile_android_instagram',
  MOBILE_ANDROID_CHROME = 'mobile_android_chrome',
  MOBILE_ANDROID_CHROME_TO_PWA = 'mobile_android_chrome_to_pwa',
  MOBILE_ANDROID_BROWSER = 'mobile_android_browser',
  MOBILE_ANDROID_PWA = 'mobile_android_pwa',
  MOBILE_IOS_INSTAGRAM = 'mobile_ios_instagram',
  MOBILE_IOS_SAFARI = 'mobile_ios_safari',
  MOBILE_IOS_BROWSER = 'mobile_ios_browser',
  MOBILE_IOS_PWA = 'mobile_ios_pwa',
  DESKTOP = 'desktop',
  ONBOARDING = 'onboarding',
}

export enum SetupPayloadDevice {
  ANDROID = 'android',
  IOS = 'ios',
  DESKTOP = 'desktop',
  ONBOARDING = 'onboarding',
}

export interface SetupInvitation {
  referral?: string | null;
  avatarId?: string | null;
  secret?: string | null;
}

export interface SetupPayload {
  device: SetupPayloadDevice;
  mode: SetupPayloadMode;
  // avatarId: string | null;
  invitation?: SetupInvitation;
  log: string[];
}

export default class Setup extends Module {
  public payload: SetupPayload = {
    device: SetupPayloadDevice.DESKTOP,
    mode: SetupPayloadMode.DESKTOP,
    // avatarId: '',
    log: [],
  };

  public constructor(application: Application) {
    super(application);
  }

  public async process(): Promise<boolean> {
    if (!(await this.loadInvitation())) return false;
    // return await this.setupInstagram();
    // return await this.setupAndroidChrome();
    // return await this.setupAndroidPwa();
    // return await this.setupIosSafari();
    // return await this.setupIosPwa();

    if (this.application.client.client.getBrowser() === 'Instagram')
      return await this.setupInstagram();

    if (this.application.client.getIsBrowserIOS())
      return await this.setupIosBrowser();

    if (this.application.client.getIsBrowserAndroid())
      return await this.setupAndroidBrowser();

    if (this.application.client.getIsSafariIOS())
      return await this.setupIosSafari();

    if (this.application.client.getIsPWAIOS()) return await this.setupIosPwa();

    if (this.application.client.getIsChromeAndroid())
      return await this.setupAndroidChrome();

    if (this.application.client.getIsPWAAndroid())
      return await this.setupAndroidPwa();

    return await this.setupDesktop();
  }

  private async setupInstagram(): Promise<boolean> {
    if (this.application.client.getIsAndroid())
      this.setPayloadDeviceMode(
        SetupPayloadDevice.ANDROID,
        SetupPayloadMode.MOBILE_ANDROID_INSTAGRAM
      );
    if (this.application.client.getIsIOS())
      this.setPayloadDeviceMode(
        SetupPayloadDevice.IOS,
        SetupPayloadMode.MOBILE_IOS_INSTAGRAM
      );

    return false;
  }

  private async setupAndroidBrowser(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.ANDROID,
      SetupPayloadMode.MOBILE_ANDROID_BROWSER
    );
    return false;
  }

  private async setupAndroidChrome(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.ANDROID,
      SetupPayloadMode.MOBILE_ANDROID_CHROME
    );

    // Chrome share the local indexedDb between browser and PWA
    // So we can create the wallet at that stage
    await this.loadOrCreateWallet();

    // If payload has avatarId and claimSecret we want to process it now
    await this.claimAvatar();

    // We stay in the unboarding screen until the user install the app
    return false;
  }

  private async setupAndroidPwa(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.ANDROID,
      SetupPayloadMode.MOBILE_ANDROID_PWA
    );

    // User should have a wallet and avatar from setupAndroidChrome
    // But he could have skip that step and install the app
    // If so, he only has the admin avatar
    await this.loadOrCreateWallet();

    return true;
  }

  private async setupIosBrowser(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.IOS,
      SetupPayloadMode.MOBILE_IOS_BROWSER
    );
    return false;
  }

  private async setupIosSafari(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.IOS,
      SetupPayloadMode.MOBILE_IOS_SAFARI
    );
    // Send fingerprint, avatarId and password to bowmerang
    // TTL is 5 min
    // Ask to install PWA
    // If avatar already in wallet, ask to install pwa
    // Grab avatar from kumaly if not in wallet
    // Check that password allow to replace avatar PK or fail
    return false;
  }

  private async setupIosPwa(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.IOS,
      SetupPayloadMode.MOBILE_IOS_PWA
    );

    await this.loadOrCreateWallet();

    return true;
  }

  private async setupDesktop(): Promise<boolean> {
    this.setPayloadDeviceMode(
      SetupPayloadDevice.DESKTOP,
      SetupPayloadMode.DESKTOP
    );

    // if (!(await this.initWallet())) return false;
    await this.loadWallet();
    return true;
  }

  // private async setupOnboarding(): Promise<boolean> {
  //   this.setPayloadDeviceMode(
  //     SetupPayloadDevice.ONBOARDING,
  //     SetupPayloadMode.ONBOARDING
  //   );
  //   return true;
  // }

  private setPayloadDeviceMode(
    device: SetupPayloadDevice,
    mode: SetupPayloadMode
  ): void {
    this.payload.device = device;
    this.payload.mode = mode;
    this.application.e.setupPayload.set(this.payload);
  }

  private setPayloadInvitation(invitation: SetupInvitation): void {
    this.payload.invitation = invitation;
    this.application.e.setupPayload.set(this.payload);
  }

  // public async setAvatarId(avatarId: string): Promise<void> {
  //   this.payload.avatarId = avatarId;
  //   this.application.e.setupPayload.set(this.payload);

  //   if (this.application.client.getIsAndroid())
  //     this.setPayloadDeviceMode(
  //       SetupPayloadDevice.ANDROID,
  //       SetupPayloadMode.MOBILE_ANDROID_CHROME_TO_PWA
  //     );
  //   if (this.application.client.getIsIOS()) {
  //     // await this.loadWallet();
  //     // this.setupOnboarding();
  //   }
  // }

  private async loadOrCreateWallet(): Promise<void> {
    if (await this.loadWallet()) return;
    await this.application.wallet.createWallet();
  }

  private async hasWallet(): Promise<boolean> {
    return await this.application.wallet.load();
  }

  private async loadWallet(): Promise<boolean> {
    if (!(await this.hasWallet())) return false;

    await this.application.wallet.load();
    return true;

    // this.application.setContext();
    // this.application.setState(ApplicationState.READY);
  }

  private async claimAvatar(): Promise<boolean> {
    if (!this.payload.invitation) return false;
    if (
      !this.payload.invitation.avatarId ||
      !this.payload.invitation.avatarId.length
    )
      return false;
    if (
      !this.payload.invitation.secret ||
      !this.payload.invitation.secret.length
    )
      return false;

    await this.application.wallet.claimAvatar(
      this.payload.invitation.avatarId,
      this.payload.invitation.secret,
      this.payload.invitation.referral
    );
  }

  // private async loadWallet(): Promise<boolean> {
  //   await this.application.wallet.init();
  //   this.application.setContext();
  //   return true;
  // }

  // private async sendInstallationMessage(): Promise<void> {
  //   const core = this.application.k.avatar;
  //   const message = {
  //     id: `${core.id}:${this.application.timestamp}`,
  //     toAvatarId: this.application.id,
  //     cypher: 'TOTO',
  //   };
  //   console.log(message);

  //   await this.application.k.loadMessagesModule();
  //   const result = await this.application.km.messages.mint(
  //     core,
  //     message,
  //     // @ts-ignore // TODO
  //     new FormData()
  //   );
  //   console.log(result);
  // }

  // private async initWallet(): Promise<boolean> {
  //   if (!(await this.application.wallet.load())) {
  //     this.application.libraries.loadBIP39('en', this.createWallet.bind(this));
  //     return true;
  //   }

  //   this.addPayloadLog('Existing wallet found');
  //   // await this.createAvatar();
  //   return true;
  // }

  // private async createWallet(): Promise<void> {
  //   this.addPayloadLog('Create wallet');
  //   await this.application.wallet.createWallet();
  //   await this.createAvatar();
  // }

  // private async createAvatar(): Promise<void> {
  //   if (!this.payload.avatarId || !this.payload.avatarId.length) return;

  //   this.addPayloadLog('Create avatar');
  //   if (this.k.wallet.wallet.avatarsIds.indexOf(this.payload.avatarId) === -1) {
  //     this.addPayloadLog('Create avatar in wallet');
  //     // await this.k.wallet.avatars.createAvatarInDb({
  //     //   id: this.payload.avatarId,
  //     //   nonce: 0,
  //     // });
  //   } else {
  //     this.addPayloadLog('Avatar found in wallet');
  //   }

  //   // this.addPayloadLog('Select avatar');
  //   // await this.application.wallet.setAvatarId(this.payload.avatarId);
  // }

  private async loadInvitation(): Promise<boolean> {
    const urlParams = new URLSearchParams(window.location.search);
    const referer = urlParams.get('referer');
    const avatarId = urlParams.get('avatarId');
    const secret = urlParams.get('secret');

    // this.payload.avatarId = avatarId;
    this.setPayloadInvitation({ referral: referer, avatarId, secret });
    return true;
  }
}
