<script lang="ts">
  import type { SubNavBarSegmentedParams } from './Interfaces';
  import { A } from '#js/Application';
  import { Button, Icon } from 'framework7-svelte';

  export let params: SubNavBarSegmentedParams;
</script>

<div class="subnavbar">
  <div class="subnavbar-inner">
    <div class="segmented segmented-strong">
      {#each params.tabs as tab}
        <Button tabLink="#{tab.id}" tabLinkActive={tab.active}>
          {#if tab.icon}
            <Icon f7={A.icons.get(tab.icon)} />
          {/if}

          {#if tab.title}
            {A.t(tab.title)}
          {/if}
        </Button>
      {/each}
      <span class="segmented-highlight" />
    </div>
  </div>
</div>
