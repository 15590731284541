<script lang="ts">
  import { A, type Page } from '#js/Application';
  import AdventureComponent from './Adventure/Component.svelte';
  import CommunityComponent from './Community/Component.svelte';
  import GiftComponent from './Gift/Component.svelte';
  import MemoryComponent from './Memory/Component.svelte';
  import RewardComponent from './Reward/Component.svelte';

  export let page: Page;
  export let components;
</script>

{#each components as component}
  {@const params = A.cloneObjectSurcharge(component.params, { page })}

  <AdventureComponent {params} {component} />
  <CommunityComponent {params} {component} />
  <GiftComponent {params} {component} />
  <MemoryComponent {params} {component} />
  <RewardComponent {params} {component} />
{/each}
