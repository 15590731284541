/* eslint-disable class-methods-use-this */
import Module from './Module';

export default class External extends Module {
  public openURL(url: string): void {
    window.open(url);
  }

  public openBlog(url: string): void {
    // TODO
    window.open(`https://www.experiencesluxe.com/${url}/`);
  }

  public async openAddress(address: string): Promise<void> {
    this.openAddressInBrowser(address);
  }

  public openAddressInBrowser(address: string): void {
    window.open(`https://www.google.com/maps/search/${encodeURI(address)}`);
  }

  public async openInstagram(path: string) {
    this.openInstagramInBrowser(path);
  }

  private openInstagramInBrowser(path: string): void {
    window.open(`https://www.instagram.com/${path}`);
  }

  // `100063559397622/posts/ pfbid0qQ3SaMiQSQDTYhB2Kqskdgu7JbiPWYWZMe2UZBwvfQ221EuPmv7qes4G8e5jss2Jl
  // 'watch/?v=1253969135360995';
  // reel/1274812666590116
  public async openFacebook(path: string) {
    this.openFacebookInBrowser(path);
  }

  private openFacebookInBrowser(path: string): void {
    window.open(`https://www.facebook.com/${path}`);
  }
}
