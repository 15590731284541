/* eslint-disable class-methods-use-this */
import { notifier, type LangId } from '@kumaly/orm';
import dayjs from 'dayjs';
import { A, type Application, type id } from '#js/Application';
import Config from '#js/Config';
import Module from './Module';

import en1 from '#locales/en.json';
import en2 from '#app/locales/en.json';
import fr1 from '#locales/fr.json';
import fr2 from '#app/locales/fr.json';

type LangsIds = 'en' | 'fr';

export default class Locales extends Module {
  public langId: LangId = Config.getDefaultLangId();

  public currencyId: id = Config.getDefaultCurrencyId();

  public texts: { [key: string]: string } = {};

  public constructor(application: Application) {
    super(application);

    notifier.on('addLocales', (datas: Record<LangsIds, string[]>) =>
      this.addLocales(datas)
    );
  }

  public initFromBrowserSettings(): void {
    this.setLangId(this.a.client.getLangId() as LangId, false);
    this.setCurrencyId(this.a.client.getCurrencyId(), false);
  }

  public async setLangId(langId: LangId, closePopup = true): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    langId = 'en' as LangId;

    this.langId = langId;
    this.e.langId.set(this.langId);

    this.LoadDayJsLocale();

    if (!closePopup) return;

    this.a.closePopup('language');
  }

  private async LoadDayJsLocale(): Promise<void> {
    if (this.langId === 'en') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await import('dayjs/locale/en').then((module) => {
        dayjs.locale('en');
      });
    }
    if (this.langId === 'fr') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await import('dayjs/locale/fr').then((module) => {
        dayjs.locale('fr');
      });
    }
  }

  public setCurrencyId(currencyId: id, closePopup = true): void {
    this.currencyId = currencyId;
    this.e.currencyId.set(this.currencyId);

    if (!closePopup) return;

    this.a.closePopup('currency');
  }

  public getText(key: string): string {
    return this.texts[`${this.langId}_${key}`] ?? '';
  }

  public addLocales(datas: Record<LangsIds, string[]>): void {
    const langsIds = Object.keys(datas);
    langsIds.forEach((langId: string) => {
      Object.keys(datas[langId]).forEach((key: string) => {
        this.texts[`${langId}_${key}`] = datas[langId][key];
      });
    });
  }

  public loadLocales(): void {
    [en1, en2].forEach((en) => {
      Object.keys(en).forEach((key: string) => {
        this.texts[`en_${key}`] = en[key];
      });
    });
    [fr1, fr2].forEach((fr) => {
      Object.keys(fr).forEach((key: string) => {
        this.texts[`fr_${key}`] = fr[key];
      });
    });
  }

  public formatNow(formatting: string): string {
    return dayjs(Date.now()).format(formatting);
  }

  public formatTimestamp(timestamp: number, formatting: string): string {
    return dayjs.unix(timestamp).format(formatting);
  }

  public formatTimeElapsed(timestamp: number): string {
    const time = Math.floor((Date.now() - timestamp) / 1000);
    if (time < 60) return '1 min';
    if (time < 3600) return `${Math.floor(time / 60)} min`;
    if (time < 86400) return `${Math.floor(time / 3600)} h`;

    return `${Math.floor(time / 86400)} days`;
  }

  public formatPrice(price: number, currencyId: string = 'usd'): string {
    const convertedPrice = this.convertToCurrency(
      price,
      currencyId,
      this.langId
    );

    if (this.langId === 'fr') {
      const priceStr = `${convertedPrice}`;
      return `${priceStr.replace('.', ',')} &euro;`;
    }

    return `$ ${price}`;
  }

  // TODO implement
  public convertToCurrency(
    value: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currencyFromId: id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    currencyToId: id
  ): number {
    return value;
  }

  public formatCrypto(value: string): string {
    return A.k.ethers.utils.formatEther(value, 'wei').toString();
  }
}
