import Config from '#js/Config';
import loader from '#js/loader';
import Module from './Module';

export default class Libraries extends Module {
  public paths = {
    BIP39: {
      // CN: `${Config.getPath()}js/bip39/bip39-chinese_simplified.browser.min.js`,
      // HK: `${Config.getPath()}js/bip39/bip39-chinese_traditional.browser.min.js`,
      // CZ: `${Config.getPath()}js/bip39/bip39-czech.browser.min.js`,
      EN: `${Config.getPath()}js/bip39/bip39-english.browser.min.js`,
      // FR: `${Config.getPath()}js/bip39/bip39-french.browser.min.js`,
      // IT: `${Config.getPath()}js/bip39/bip39-italian.browser.min.js`,
      // JA: `${Config.getPath()}js/bip39/bip39-japanese.browser.min.js`,
      // KO: `${Config.getPath()}js/bip39/bip39-korean.browser.min.js`,
      // PT: `${Config.getPath()}js/bip39/bip39-portuguese.browser.min.js`,
      // ES: `${Config.getPath()}js/bip39/bip39-spanish.browser.min.js`,
    },
  };

  public BIP39: any;

  public loadBIP39(langId: string, callback: Function) {
    const url = this.paths.BIP39[langId.toUpperCase()];
    loader([{ type: 'script', url }], this.testBIP39, async () => {
      // @ts-ignore
      this.BIP39 = window.bip39;
      // await this.callbackBIP39();
      await callback();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private testBIP39() {
    return false;
  }

  // private async callbackBIP39() {
  //   console.log('BIP39 Loaded.');
  //   // @ts-ignore
  //   this.BIP39 = window.bip39;
  //   await this.a.createWallet();
  // }
}
