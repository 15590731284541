<script lang="ts">
  import { A } from '#js/Application';
  import type { id, Props } from '#js/Interfaces';
  import { Page } from 'framework7-svelte';
  import Components from '#components/Components.svelte';

  export let f7route;
  export let f7router;

  // Silence f7router not used warning
  f7router.on('routeChanged', (e) => {});

  // TODO
  //  f7router.on('routeChanged', (e) => console.log(e.url));
  //  f7router.on('pageBeforeIn', (e) => console.log(e.route.url));

  export let adventureId: id = null;
  export let articleId: id = null;
  export let collectionId: id = null;
  export let contractId: id = null;
  export let experienceId: id = null;
  export let giftId: id = null;
  export let variationId: id = null;

  let showPreloader: boolean = false;

  let props: Props = {
    adventureId,
    articleId,
    collectionId,
    contractId,
    experienceId,
    giftId,
    variationId,
  };

  let page = A.pages.loadPageByPath(f7route.path, props, f7route.query);
  page.transaction = A.startPageTransaction(page.path);

  async function load(data: { pageEl: HTMLElement }) {
    page.el = data.pageEl;
  }

  function onPageAfterIn() {
    if (page.path === '/sidebar') return;

    // First page load, then it's triggered by Skeleton:activateTab
    // if (page.path == '/notifications') {
    //   console.log('/notifications');
    //   A.k.setUserNotificationsTimestamp();
    // }

    A.pages.loadPageComplete(page);
  }

  function onPageBeforeIn() {
    if (page.path.includes('/selections/')) A.hideToolbar();
  }

  // Done is provided by f7
  function pageRefresh(done) {
    console.log('PAGE REFRESH ASK');
    A.pages.refreshPage(page, done);
  }

  function pageLoadMore() {
    A.pages.loadMorePage(page, pageLoadMoreDone);
  }

  function pageLoadMoreDone() {
    showPreloader = false;
  }

  function pageContent(): boolean {
    return (
      !A.pages.checkPageHasSubNavBarSegmented(page) &&
      // To remove the padding at the bottom of page-content
      page.path.substring(0, '/selections/articles/'.length) !=
        '/selections/articles/'
    );
  }

  A.e.pageLoadMoreStarted.subscribe(async (value: string) => {
    if (value != page.path) return;

    showPreloader = true;
  });
</script>

<Page
  name="page"
  onPageInit={load}
  ptr={A.pages.checkPageHasPullToRefresh(page)}
  onPtrRefresh={pageRefresh}
  pageContent={pageContent()}
  withSubnavbar={A.pages.checkPageHasSubNavBarSegmented(page)}
  {onPageAfterIn}
  {onPageBeforeIn}
  infinite={A.pages.checkPageHasInfiniteLoad(page)}
  infiniteDistance={150}
  infinitePreloader={showPreloader}
  onInfinite={pageLoadMore}
>
  <Components {page} components={page.components} />

  <img
    alt="lazy init"
    data-src=""
    width="1"
    height="1"
    class="lazy lazy-fade-in demo-lazy"
    style="display:none;"
  />
</Page>
