<script lang="ts">
  // @ts-ignore
  import { useRegisterSW } from 'virtual:pwa-register/svelte';
  import type { ReloadPromptParams } from './Interfaces';
  import { A } from '#js/Application';
  import Button from '#components/Button/Button.svelte';

  export let params: ReloadPromptParams;

  const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
    onNeedRefresh() {
      console.log('On need refresh');
    },
    onOfflineReady() {
      console.log('On offline ready');
    },
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (!(!r.installing && navigator)) return;

          if ('connection' in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: 'no-store',
            headers: {
              cache: 'no-store',
              'cache-control': 'no-cache',
            },
          });
          console.log(resp);

          if (resp?.status === 200) await r.update();
        }, 70 * 1000);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });
  $: toast = $offlineReady || $needRefresh;

  function updateNow() {
    console.log('UPDATE');
    updateServiceWorker();
    console.log('DONE');
    A.reload(10);
  }
</script>

{#if toast}
  <div class="m-4 px-4">
    <Button
      params={{
        page: params.page,
        text: A.t('reloadpromp.button'),
        onClick: updateNow,
      }}
    />
  </div>
{/if}
