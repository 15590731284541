<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadGiftPage() {
    import('#app/components/Gift/GiftPage/GiftPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  function loadGiftsPage() {
    import('#app/components/Gift/GiftsPage/GiftsPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'GiftPage') loadGiftPage();
  if (component.id == 'GiftsPage') loadGiftsPage();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
