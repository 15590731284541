import type { Application } from '#js/Application';
import type Events from '#js/kernel/Events';
import type { GuiKernel } from '#js/kernel/GuiKernel';

export default class Module {
  protected application: Application;

  public constructor(application: Application) {
    this.application = application;
  }

  public get a(): Application {
    return this.application;
  }

  public get e(): Events {
    return this.application.k.events;
  }

  public get k(): GuiKernel {
    return this.application.k;
  }
}
