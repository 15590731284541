<script lang="ts">
  import { A } from '#js/Application';
  import { SetupPayloadDevice, type SetupPayload } from '#js/modules/Setup';
  import type { SetupParams } from './Interfaces';
  import ReloadPrompt from '#components/ReloadPrompt/ReloadPrompt.svelte';
  import SetupOnboarding from '../SetupOnboarding/SetupOnboarding.svelte';
  import SetupAndroid from '../SetupAndroid/SetupAndroid.svelte';
  import SetupIos from '../SetupIos/SetupIos.svelte';
  import SetupDesktop from '../SetupDesktop/SetupDesktop.svelte';

  export let params: SetupParams;

  let payload: SetupPayload;
  let avatarId: string = '';

  A.e.setupPayload.subscribe(async (value: SetupPayload) => {
    payload = value;
    avatarId = payload.avatarId;
  });

  // function force() {
  //   A.state.set(ApplicationState.READY);
  // }

  // async function clearWallet() {
  //   await A.k.walletsDb.clear();
  // }

  // async function setAvatarId() {
  //   await A.setup.setAvatarId(avatarId);
  // }

  // async function start() {
  //   await A.setup.start();
  // }
</script>

<ReloadPrompt params={{ page: params.page }} />

{#if payload.device === SetupPayloadDevice.ANDROID}
  <SetupAndroid params={{ page: params.page, payload }} />
{/if}

{#if payload.device === SetupPayloadDevice.IOS}
  <SetupIos params={{ page: params.page, payload }} />
{/if}

{#if payload.device === SetupPayloadDevice.DESKTOP}
  <SetupDesktop params={{ page: params.page, payload }} />
{/if}

<!-- {#if payload.device === SetupPayloadDevice.ONBOARDING}
  <SetupOnboarding params={{ page: params.page, payload }} />
{/if} -->

<!-- <div class="bg-gray-200 mt-4 p-4">
    {#each payload.log as log}
      <div class="">{log}</div>
    {/each}
  </div> -->

<!-- <div class="bg-gray-200 mt-4 p-4">
    <Button
      params={{
        page: {},
        text: 'FORCE',
        onClick: force,
      }}
    />
  </div>

  <div class="bg-gray-200 mt-4 mb-20 p-4">
    <Button
      params={{
        page: {},
        text: 'CLEAR',
        onClick: clearWallet,
      }}
    />
  </div> -->
