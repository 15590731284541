<script lang="ts">
  import type { LinkParams } from './Interfaces';
  import { A } from '#js/Application';
  import { Icon, Badge, f7 } from 'framework7-svelte';

  export let params: LinkParams;

  let badge: number = 0;
  let tab: string;
  A.tab.subscribe((value) => {
    tab = value;
  });

  async function onClick(link: LinkParams) {
    if (link.popup) {
      A.openPopup(link.popup);
      return;
    }

    if (link.method) {
      link.method(link.methodParams);
      return;
    }

    if (link.tab) {
      // Already on active tab and dont try to access a subpage but clicked on the tab link
      if (tab == link.tab && !link.hasOwnProperty('path')) {
        const hasHistory = f7.views[link.tab].router.history.length == 1;
        hasHistory ? scrollTop(link.tab) : f7.views[link.tab].router.back();
      } else if (link.path) f7.views[link.tab].router.navigate(link.path);

      f7.tab.show(`#tab-${link.tab}`, true);
      A.tab.set(link.tab);
    }
  }

  function scrollTop(tab: string): void {
    document
      .querySelector(`#view-${tab} .page-content`)
      .scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  params.badge?.subscribe(async (value) => {
    badge = value;
    console.log(badge);
  });
</script>

{#if params.type == 'tab'}
  <a
    class={`tab-link ${tab == params.tab ? 'tab-link-active' : null}`}
    href={'#'}
    data-tab={`#tab-${params.tab}`}
    on:click={async () => await onClick(params)}
  >
    {#if params.icon === 'rewards'}
      {#if tab === params.tab}
        <img src="{A.p}eos/28b.png" alt="EOS" />
      {:else}
        <img src="{A.p}eos/28.png" alt="EOS" />
      {/if}
    {:else}
      <Icon f7={A.icons.get(params.icon)}>
        {#if badge}
          <Badge color="red">{badge}</Badge>
        {/if}
      </Icon>
    {/if}
    <span class="tabbar-label">{A.t(params.title)}</span>
  </a>
{:else}
  <a
    class={`
    item-link
    ${params.back ? 'back' : ''}
    ${params.popoverClose ? 'popover-close' : ''}
    ${params.popupClose ? 'popup-close' : ''}
    ${params.popover ? 'popover-open' : ''}
    ${params.tab && tab == params.tab ? 'item-link-active' : ''}
  `}
    href={params.tab ? null : params.path}
    data-popover={params.popover ? `.popover-${params.popover}` : null}
    data-view={params.view ? `#view-${params.view}` : null}
    on:click={async () => await onClick(params)}
  >
    <div class="item-content">
      {#if params.icon}
        <div class="item-media {params.iconClasses}">
          <Icon f7={A.icons.get(params.icon)} size={params.iconSize} />
        </div>
      {/if}

      <div class="item-inner">
        {#if params.title}
          <div class="item-title-row">
            <div class="item-title">
              {#if params.icon}
                {#if params.titleIcon === 'rewards'}
                  <img
                    src="{A.p}eos/28.png"
                    alt="EOS"
                    class="float-left pr-1"
                  />
                {:else}
                  <Icon f7={A.icons.get(params.titleIcon)} color="gray" />
                {/if}
              {/if}

              {A.t(params.title)}
            </div>
          </div>
        {/if}

        {#if params.text}
          <div class="item-title-row">
            <div class="item-title">
              {params.text}
            </div>
          </div>
        {/if}
      </div>
    </div>
  </a>
{/if}
