import type { Response } from '@kumaly/orm';
import type { KernelModules } from '@kumaly/kernel-types';
import type { ArticlesDatabase } from './Database/Articles';
import type { AvatarsDatabase } from './Database/Avatars';
import type { CollectionsDatabase } from './Database/Collections';
import type { ContractsDatabase } from './Database/Contracts';
import type { TagsDatabase } from './Database/Tags';
import type { VariationsDatabase } from './Database/Variations';
import Module from './Module';

export default class Database extends Module {
  public articles?: ArticlesDatabase;

  public avatars?: AvatarsDatabase;

  public collections?: CollectionsDatabase;

  public contracts?: ContractsDatabase;

  public tags?: TagsDatabase;

  public variations?: VariationsDatabase;

  public get km(): KernelModules {
    return this.k.modules;
  }

  public async preload(): Promise<void> {
    console.log('PRELOAD datas');

    await Promise.all([
      this.loadCollections(),
      this.loadContracts(),
      this.loadTags(),
      this.loadVariations(),
    ]);

    await Promise.all([
      this.collections.getManyApplication(),
      this.contracts.getManyApplication(),
      this.tags.getManyApplication(),
      this.variations.getManyApplication(),
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  public printError(result: Response): null {
    // eslint-disable-next-line no-console
    console.log(result.code);
    return null;
  }

  public async loadArticles(): Promise<void> {
    if (this.articles) return;
    const { ArticlesDatabase } = await import('./Database/Articles');
    this.articles = new ArticlesDatabase(this);
  }

  public async loadAvatars(): Promise<void> {
    if (this.avatars) return;
    const { AvatarsDatabase } = await import('./Database/Avatars');
    this.avatars = new AvatarsDatabase(this);
  }

  public async loadCollections(): Promise<void> {
    if (this.collections) return;
    const { CollectionsDatabase } = await import('./Database/Collections');
    this.collections = new CollectionsDatabase(this);
  }

  public async loadContracts(): Promise<void> {
    if (this.contracts) return;
    const { ContractsDatabase } = await import('./Database/Contracts');
    this.contracts = new ContractsDatabase(this);
  }

  public async loadTags(): Promise<void> {
    if (this.tags) return;
    const { TagsDatabase } = await import('./Database/Tags');
    this.tags = new TagsDatabase(this);
  }

  public async loadVariations(): Promise<void> {
    if (this.variations) return;
    const { VariationsDatabase } = await import('./Database/Variations');
    this.variations = new VariationsDatabase(this);
  }
}
