<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadHowtoPopupPage() {
    import('#components/Howto/HowtoPopupPage/HowtoPopupPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'HowtoPopupPage') loadHowtoPopupPage();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
