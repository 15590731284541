/* eslint-disable class-methods-use-this */
import { ClientJS } from 'clientjs';
import type { Application, id } from '#js/Application';
import { getCurrency } from '#js/utils/Currency';
import Module from './Module';

export default class Client extends Module {
  public client: ClientJS;

  private width: number;

  public constructor(application: Application) {
    super(application);
    this.client = new ClientJS();
    this.width = this.getResolutionWidth();

    this.refreshDarkMode();
  }

  private refreshDarkMode(): void {
    this.a.e.darkMode.set(this.getDarkMode());
    setTimeout(() => {
      this.refreshDarkMode();
      const client = new ClientJS();
      const width = client.getCurrentResolution().split('x')[0];
      if (width !== this.width) {
        console.log(`Change resolution to ${width}`);
        this.a.e.clientWidth.set(width);
        this.width = width;
      }
    }, 1000);
  }

  public getDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme:dark)').matches;
  }

  public getIsPWAIOS(): boolean {
    return this.getIsIOS() && this.getIsPWA();
  }

  public getIsPWAAndroid(): boolean {
    return this.getIsAndroid() && this.getIsPWA();
  }

  public getIsSafariIOS(): boolean {
    return this.getIsIOS() && !this.getIsPWA() && this.client.isMobileSafari();
  }

  public getIsBrowserIOS(): boolean {
    return this.getIsIOS() && !this.getIsPWA() && !this.client.isMobileSafari();
  }

  public getIsChromeAndroid(): boolean {
    return (
      this.getIsAndroid() &&
      !this.getIsPWA() &&
      this.client.getBrowser() === 'Chrome'
    );
  }

  public getIsBrowserAndroid(): boolean {
    return (
      this.getIsAndroid() &&
      !this.getIsPWA() &&
      this.client.getBrowser() !== 'Chrome'
    );
  }

  public getIsIOS(): boolean {
    return this.client.isMobileIOS();
  }

  public getIsAndroid(): boolean {
    return this.client.isMobileAndroid();
  }

  public getIsPWA(): boolean {
    if (
      this.client.isMobileIOS() &&
      'standalone' in window.navigator &&
      // @ts-ignore
      window.navigator.standalone
    )
      return true;

    if (
      this.client.isMobileAndroid() &&
      window.matchMedia('(display-mode: standalone)').matches
    )
      return true;

    // TODO
    console.log('Check PWA on desktop not implemented');
    return false;
  }

  public getIsBrowser(): boolean {
    return !this.getIsPWA();
  }

  public getFingerprint(): string {
    return this.client.getFingerprint();
  }

  public getLangId(): id {
    return this.languageToLangId(this.client.getLanguage());
  }

  public getCurrencyId(): id {
    return getCurrency(this.client.getLanguage());
  }

  public getResolutionWidth(): number {
    return this.client.getCurrentResolution().split('x')[0];
  }

  public getResolutionWidthMain(): number {
    if (this.getResolutionWidth() >= 1024)
      return this.getResolutionWidth() - 260;

    return this.getResolutionWidth();
  }

  public getResolutionHeight(): number {
    return this.client.getCurrentResolution().split('x')[1];
  }

  public getPixelRatio(): number {
    return window.devicePixelRatio;
  }

  public getCappedPixelRatio(max: number): number {
    return this.getPixelRatio() < max ? this.getPixelRatio() : max;
  }

  public getBrowser(): string {
    return this.client.getBrowser();
  }

  public getOS(): string {
    return this.client.getOS();
  }

  public getUserAgent(): string {
    return this.client.getUserAgent();
  }

  private languageToLangId(language: string): id {
    return language.split('-')[0];
  }
}
