<script lang="ts">
  import { A } from '#js/Application';
  import { Button } from 'framework7-svelte';
  import type { ButtonParams } from './Interfaces';

  export let params: ButtonParams;
  A.void(params);
</script>

<div class="">
  {#if params.onClick}
    <Button
      fill
      large
      class={params.classes}
      disabled={params.disabled || false}
      on:click={async () => await params.onClick()}
      style="text-transform: none;"
    >
      <slot name="text">
        {params.text}
      </slot>
    </Button>
  {:else}
    <Button
      fill
      large
      disabled={params.disabled || false}
      class={params.classes}
      href={params.href}
      view={params.view}
      style="text-transform: none;"
    >
      <slot name="text">
        {params.text}
      </slot>
    </Button>
  {/if}
</div>
