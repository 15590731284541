<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  // This component has to be preloaded and cannot be imported
  // from a function otherwise there is css issues
  import NavBar from '#components/NavBar/NavBar/NavBar.svelte';
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}

{#if component.id == 'NavBar'} <NavBar {params} /> {/if}
