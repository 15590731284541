<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  function loadArea() {
    import('#components/Area/Area/Area.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  if (component.id == 'Area') loadArea();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
