/* eslint-disable import/order */
/* eslint-disable import/extensions */

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import Config from '#js/Config';

Sentry.init({
  dsn: Config.getSentryDSN(),
  // @ts-ignore
  integrations: [new BrowserTracing()],

  environment: Config.getEnvironment(),
  release: Config.getVersion(),
  tracesSampleRate: Config.getSentryRate(),
});

// Import Styles
// Has to be done before importing components
// Because each components needs to override with it's own css !
import '#app/css/framework7.less';
import '#css/icons.css';
import '#css/app.less';
import '#app/css/app.less';

// import Framework7 from 'framework7/bundle';// Full F7 for debug only
import Framework7 from '#app/js/framework7';
import Framework7Svelte from 'framework7-svelte';

// Import App Component
import App from '#components/App.svelte';

// Init F7 Svelte Plugin
Framework7.use(Framework7Svelte);

// Mount Svelte App
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = new App({
  target: document.getElementById('app'),
});
