/* eslint-disable class-methods-use-this */
import { f7 } from '#js/Application';
import Module from './Module';

export default class Framework7 extends Module {
  public async loadCardModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'card')) return;

    // @ts-ignore
    await import('framework7/components/card').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadCheckboxModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'checkbox')) return;

    // @ts-ignore
    await import('framework7/components/checkbox').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadDialogModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'dialog')) return;

    // @ts-ignore
    await import('framework7/components/dialog').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadInputModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'input')) return;

    // @ts-ignore
    await import('framework7/components/input').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadPopoverModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'popover')) return;

    // @ts-ignore
    await import('framework7/components/popover').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadPopupModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'popup')) return;

    // @ts-ignore
    await import('framework7/components/popup').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadRadioModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'radio')) return;

    // @ts-ignore
    await import('framework7/components/radio').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadRangeModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'range')) return;

    // @ts-ignore
    await import('framework7/components/range').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadStepperModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'stepper')) return;

    // @ts-ignore
    await import('framework7/components/stepper').then((module) =>
      f7.loadModule(module.default)
    );
  }

  public async loadSwiperModule(): Promise<void> {
    if (Object.prototype.hasOwnProperty.call(f7, 'swiper')) return;

    // @ts-ignore
    await import('framework7/components/swiper').then((module) => {
      console.log('SWIPER MODULE LOAD');
      f7.loadModule(module.default);
    });
  }
}
