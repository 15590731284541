import type { ids, network } from '@kumaly/orm';
import { TagsDtoSort } from '@kumaly/orm-tags';
import type {
  GetPurseCorePMRK,
  GetAvatarPurseACRK,
  GetWalletCoreWMRK,
  KernelParams,
  LoadAvatarFromDbAMRK,
  LoadPurseFromMetamaskPMRK,
} from '@kumaly/kernel-types';
import type { AvatarCore } from '@kumaly/kernel-avatar';
import type { PurseCore } from '@kumaly/kernel-purse';
import type { WalletCore } from '@kumaly/kernel-wallet';
import { Kernel } from '@kumaly/kernel';

import Config from '#js/Config';
import type { Application } from '#js/Application';
import Events from '#js/kernel/Events';

declare global {
  interface Window {
    ethereum: import('ethers').providers.ExternalProvider;
  }
}

export class GuiKernel extends Kernel {
  public application: Application;

  public events: Events = new Events();

  public wallet: WalletCore;

  public avatar: AvatarCore;

  public purse: PurseCore;

  public constructor(application: Application) {
    const params: KernelParams = {
      network: Config.getNetwork() as network,
      cdnUrl: Config.getCdnUrl(),
      gatewayUrl: Config.getGatewayUrl(),
    };

    super(params);

    this.application = application;
  }

  public get langId(): string {
    return this.application.locales.langId;
  }

  public getFilterTagsIdsSortByNameAsc(ids: ids): ids {
    return this.modules.tags.hub.items.filter({
      page: 1,
      limit: 1000,
      sort: TagsDtoSort[`LOCALES_${this.langId.toLocaleUpperCase()}_NAME_ASC`],
      ids,
    }).ids;
  }

  // **** WALLET

  public async loadWallet(): Promise<void> {
    const result1: GetWalletCoreWMRK =
      this.cores.wallets!.getWalletCore('wallet');
    this.wallet = result1.datas as WalletCore;

    await this.loadAvatar();
  }

  public async loadAvatar(): Promise<void> {
    const result2: LoadAvatarFromDbAMRK =
      await this.cores.avatars!.loadAvatarFromDb(
        this.wallet.id,
        this.wallet.wallet.avatarId
      );
    this.avatar = result2.datas as AvatarCore;

    await this.loadPurse();
  }

  public async loadPurse(): Promise<void> {
    await this.loadEthers();
    await this.avatar.loadPursesModule();
    const result3: GetAvatarPurseACRK = await this.avatar.purses!.getPurse(
      'localhost'
    );

    this.purse = result3.datas as PurseCore;
  }

  // **** PURSE

  public async loadEthers(): Promise<void> {
    if (this.ethers) return;
    const { ethers } = await import('ethers');
    this.ethers = ethers;
  }

  public async connectWithPurse(): Promise<boolean> {
    let chainId = await this.getMetamaskChainId();
    if (!chainId) return false;

    // TODO : we want to use the one given by metamask, not params
    if (chainId === '0x89') chainId = 'polygon';
    if (chainId === '0x28') chainId = 'telosevm';

    const result: LoadPurseFromMetamaskPMRK =
      await this.cores.purses.loadPurseFromMetamask(
        chainId,
        chainId,
        this.metamask
      );
    if (!result.success) {
      console.log(result.code);
      return false;
    }

    const result2: GetPurseCorePMRK = this.cores.purses.getPurseCore(chainId);
    if (!result2.success) {
      console.log(result2.code);
      return false;
    }
    this.purse = result2.datas;
    if (!this.purse) return false;

    if (await this.purse.connect()) {
      this.events.purse.set(true);

      // await DB.purses.setPurseChainId(params.chainId);
      // await db.purses.setPurseChainId(chainId);
      await this.purse.metamask.request({ method: 'eth_accounts' });

      return true;
    }

    return false;
  }

  public async initPurse(): Promise<void> {
    const purse = this.metamask;
    if (!purse) return;

    await this.connectWithPurse();
  }

  /*
  public async disconnectPurse() : Promise<boolean> {
    await DB.purses.setPurseChainId(null);
    this.application.reload(100);

    return false;
  }
  */

  // **** METAMASK

  // eslint-disable-next-line class-methods-use-this
  public get metamask(): any | null {
    const { ethereum } = window;

    if (!ethereum) return null;

    return ethereum;
  }

  public hasMetamask(): boolean {
    return this.metamask !== null;
  }

  public async getMetamaskChainId(): Promise<string | null> {
    if (!this.metamask) return null;

    return await this.metamask.request({ method: 'eth_chainId' });
  }
}
