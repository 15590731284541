<script lang="ts">
  import { A, onMount } from '#js/Application';
  import { f7, Page, Popup, View } from 'framework7-svelte';
  import type { TransactionPopupParams } from './Interfaces';

  export let params: TransactionPopupParams;
  A.void(params);

  let loaded: boolean = false;

  onMount(async () => {
    await A.framework7.loadPopupModule();
    loaded = true;
  });

  function onPopupOpen(): void {
    onViewInit();
  }

  // This is required to fix a safari but where the last page loaded in the popup
  // is visible for a second when the popup is reopened before it's replaced by
  // the new page. Forcing the reloadAll prevent that side effect.
  function onPopupClose(): void {
    const view = f7.views.get('#view-popup-transaction');
    view.router.navigate('/popups/transaction', { reloadAll: true });
  }

  function onViewInit(): void {
    const view = f7.views.get('#view-popup-transaction');
    view.router.navigate('/popups/transaction', { reloadCurrent: true });
  }

  function onSwipeBackMove(event): void {
    console.log('SWIPE BACK MOVE');

    const view = f7.views.get('#view-popup-transaction');
    console.log(view);
    // view.router.back();
  }
</script>

{#if loaded}
  <Popup
    class="popup-transaction"
    swipeToClose="to-bottom"
    push
    on:popupOpen={onPopupOpen}
    on:popupClose={onPopupClose}
  >
    <View id="view-popup-transaction" {onSwipeBackMove}>
      <Page />
    </View>
  </Popup>
{/if}
