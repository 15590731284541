import type { ModuleSkeleton, PageSkeleton, Tab } from '#js/Interfaces';

export default class SkeletonDatas {
  public tabs: Tab[] = [];

  public modules?: ModuleSkeleton[] = [];

  public pages: PageSkeleton[] = [];

  public constructor(skeleton: SkeletonDatas) {
    this.tabs = skeleton.tabs;
    this.modules = skeleton.modules || [];
    this.pages = skeleton.pages;
  }
}
