<script lang="ts">
  export let params: any;
  export let component;

  let svelte;

  // function loadRewardPage() {
  //   import('#app/components/Reward/RewardPage/RewardPage.svelte')
  //     .then((result) => (svelte = result.default))
  //     .catch((err) => console.error(err));
  // }

  function loadRewardsPage() {
    import('#app/components/Reward/RewardsPage/RewardsPage.svelte')
      .then((result) => (svelte = result.default))
      .catch((err) => console.error(err));
  }

  // if (component.id == 'RewardPage') loadRewardPage();
  if (component.id == 'RewardsPage') loadRewardsPage();
</script>

{#if svelte}
  <svelte:component this={svelte} {params} />
{/if}
