/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-named-default */
import type { Page, PageSkeleton } from '#js/Interfaces';
import SkeletonDatas from '#js/modules/SkeletonDatas';
import { default as adventures } from '#modules/adventures.json';
import { default as avatar } from '#modules/avatar.json';
import { default as community } from '#modules/community.json';
import { default as gifts } from '#modules/gifts.json';
import { default as howto } from '#modules/howto.json';
import { default as leaderboard } from '#modules/leaderboard.json';
import { default as memories } from '#modules/memories.json';
import { default as lootbox } from '#modules/lootbox.json';
import { default as quizzes } from '#modules/quizzes.json';
import { default as rewards } from '#modules/rewards.json';
import { default as selections } from '#modules/selections.json';
import { default as transaction } from '#modules/transaction.json';

const MODULES = {
  adventures,
  avatar,
  community,
  gifts,
  howto,
  leaderboard,
  memories,
  lootbox,
  quizzes,
  rewards,
  selections,
  transaction,
};

export default class Skeleton extends SkeletonDatas {
  private paths: string[] = [];

  public constructor(skeleton: SkeletonDatas) {
    super(skeleton);

    this.initModules();
    this.initCleanedPaths();
  }

  public getPageByPath(path: string): PageSkeleton | null {
    const parts = path.split('/');
    for (let i = 0; i < this.paths.length; i += 1) {
      if (this.paths[i] === path) return this.pages[i];

      const tryPath = this.paths[i].split('/');
      if (this.checkPathMatches(parts, tryPath)) return this.pages[i];
    }

    return null;
  }

  public checkPageHasPullToRefresh(page: Page): boolean {
    return (
      Object.prototype.hasOwnProperty.call(page, 'pullToRefresh') &&
      page.pullToRefresh
    );
  }

  public checkPageHasInfiniteLoad(page: Page): boolean {
    return (
      Object.prototype.hasOwnProperty.call(page, 'infiniteLoad') &&
      page.infiniteLoad
    );
  }

  public checkPageHasSubNavBarSegmented(page: Page): boolean {
    if (!page) return false;

    for (let i = 0; i < page.components.length; i += 1)
      if (page.components[i].id === 'SubNavBarSegmented') return true;

    return false;
  }

  public getPageSubNavBar(page: Page): any {
    for (let i = 0; i < page.components.length; i += 1)
      if (page.components[i].id === 'SubNavBarSegmented')
        return page.components[i];

    return false;
  }

  private checkPathMatches(path: string[], model: string[]) {
    if (path.length !== model.length) return false;

    for (let i = 0; i < model.length; i += 1)
      if (path[i] !== model[i] && model[i] !== '*') return false;

    return true;
  }

  private initModules(): void {
    for (let i = 0; i < this.modules.length; i += 1) {
      const module = MODULES[this.modules[i].id];
      for (let j = 0; j < module.pages.length; j += 1)
        this.pages.push(module.pages[j]);
    }
  }

  public getModule(id: string): any {
    for (let i = 0; i < this.modules.length; i += 1)
      if (this.modules[i].id === id) return this.modules[i];

    return null;
  }

  private initCleanedPaths(): void {
    this.paths = [];
    for (let i = 0; i < this.pages.length; i += 1)
      this.paths.push(this.getCleanedPath(this.pages[i].path));
  }

  private getCleanedPath(path: string): string {
    const parts = path.split('/');
    for (let i = 0; i < parts.length; i += 1)
      if (parts[i].startsWith(':')) parts[i] = '*';

    return parts.join('/');
  }
}
