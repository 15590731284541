import type { LangId, network } from '@kumaly/orm';

export default class Config {
  public static getVersion(): string {
    return import.meta.env.VITE_VERSION as string;
  }

  public static getEnvironment(): string {
    return import.meta.env.VITE_ENVIRONMENT as string;
  }

  public static getSentryDSN(): string {
    return import.meta.env.VITE_SENTRY_DSN as string;
  }

  public static getSentryRate(): number {
    return import.meta.env.VITE_SENTRY_RATE as number;
  }

  public static getPath(): string {
    return import.meta.env.VITE_PATH as string;
  }

  public static getChainId(): string {
    return import.meta.env.VITE_CHAIN_ID as string;
  }

  public static getNetwork(): network {
    return import.meta.env.VITE_NETWORK as network;
  }

  public static getGatewayUrl(): string {
    return import.meta.env.VITE_HUB_BASE_URL_GATEWAY as string;
  }

  public static getCdnUrl(): string {
    return import.meta.env.VITE_CDN_BASE_URL;
  }

  public static getDefaultLangId(): LangId {
    return 'en';
  }

  public static getDefaultCurrencyId(): string {
    return 'USD';
  }
}
